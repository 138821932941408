import { LitElement, css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';

@customElement('app-progress')
export class AppProgress extends LitElement {

    static styles = css`
      .progress__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .progress {
        appearance: none;
        height: 12px;
        margin-top: 30px;
        margin-bottom: 8px;
        border: 1px solid #fff;
      }
        /* Chrome and Safari */
      .progress::-webkit-progress-bar {
        background: #CC2229;
        }

      .progress::-webkit-progress-value {
          background-color: #fff;
        }
      .progress::-webkit-progress-value {
        transition: width 1s;
      }

        /* Firefox */
      .progress::-moz-progress-bar {
          background-color: #fff;
        }
      
      .progress__text {
        font-size: 12px;
      }
      
      
  `;

    render() {
        return html`
            <div class="progress__wrapper">
                <progress class="progress" value=${this.percent} max=100></progress>
                <span class="progress__text">${this.percent}%</span>  
            </div>
    `;
    }
}
