import { css } from 'lit';

// these styles can be imported from any component
// for an example of how to use this, check /pages/about-about.ts
export const styles = css`
  @media(min-width: 1000px) {
    sl-card {
      max-width: 70vw;
    }
  }

  main {
    padding: 12px;
    padding-bottom: 120px;
    min-height: calc(100vh - 106px);
  }
  
  img, picture {
    display: block;
    max-width: 100%;
  }

  .jl-input {
    width: 100%;
    position: relative;
    display: flex;
  }
  .jl-input-icon {
    position: absolute;
    top: 50%;
    left: 16px;
    z-index: 200;
    line-height: normal;
    pointer-events: none;
    transform: translateY(-50%);
  }
  .jl-input input {
    width: 100%;
    padding: 16px 12px;
    padding-left: 43px;
    font-size: 16px;
    color: #454545;
    font-family: "Montserrat", sans-serif;
    border: 1px solid #A9A9A9;
    border-radius: 10px;
  }


  .jl-heading {
    margin: 0;
  }
  
  .jl-heading.block-heading {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #000000;
  }

  .jl-heading.page-heading {
    font-size: 32px;
    text-align: center;
    line-height: 38px;
    font-weight: 600;
    color: #CC2229;
    margin-bottom: 48px;
  }
  .jl-heading.section-heading {
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    color: #CC2229;
  }
  .jl-heading.sub-heading {
    font-size: 18px;
    text-align: left;
    line-height: 22px;
    font-weight: 600;
    color: #CC2229;
  }

  .jl-callout {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 30px 20px;
    color: #454545;
    display: flex;
    justify-content: flex-end;
  }
  .jl-callout__content {
    width: 62%;
    display: flex;
    flex-direction: column;
  }
  .jl-callout__title {
    color: #CC2229;
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    margin-top: 16px;
    line-height: 1;
  }
  .jl-callout__desc {
    margin: 12px 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .jl-button {
    display: block;
    user-select: none;
    position: relative;
    width: fit-content;
    min-width: 128px;
    font-size: 16px;
    font-weight: 600;
    hyphens: auto;
    line-height: 1.1;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    background: #CC2229;
    border-style: solid;
    border-width: 2px;
    border-color: #CC2229;
    border-radius: 10px;
    padding: 12px 24px 13px !important;
    color: #FFFFFF;
  }


  .jl-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  }
  .jl-grid__block {
    border: 1px solid #A9A9A9;
    background: #FFFFFF;
    padding: 12px 12px;
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    text-decoration: none;
  }

  .jl-grid__block-image {
    height: 102px;
    width: 82px;
    background: lightgrey;
    margin-bottom: 12px;
  }
  .jl-grid__block-icon {
    margin-bottom: 12px;
  }

  .jl-icon-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1;
    column-gap: 8px;
  }
  .jl-icon-text__svg {
    display: block;
    height: 14px;
    width: 14px;
  }
  .jl-icon-text svg {
    width: 100%;
    fill: currentcolor;
    height: inherit;
    width: inherit;
    vertical-align: initial;
  }
  
  #launcher {
    display: none;
  }
`;
