import { LitElement, css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';
import { resolveRouterPath, router } from '../router';
@customElement('app-navigation')
export class AppNavigation extends LitElement {

  static styles = css`
    .nav {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      background: #000;
      color: #fff;

      position: fixed;
      left: 0;
      bottom: 0;
      height: env(titlebar-area-height, 70px);
      width: env(titlebar-area-width, 100%);
      padding: 5px 0px;
      z-index: 300;
    }
    
    .nav__button {
      display: flex;
      width: 20%;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      text-decoration: none;
      row-gap: 8px;
    }
    
    .nav__button-icon {
      width: 18px;
      height: 18px;
    }
    
    .nav__button-icon svg {
      fill: white;
    }
    
    .nav__button-title {
      color: #ffffff;
      font-size: 12px;
      font-weight: 600;
      text-decoration: none;
    }

    .nav__button.active .nav__button-icon svg {
      fill: #E9262A;
    }
    .nav__button.active .nav__button-title {
      color: #E9262A;
    }
  `;

  renderNavButton(route) {

    //require icon to show in navigation (filters out sub-routes)
    if(!route.icon) {
      return;
    }

    if(router.context.url.pathname.includes(route.title.toString().toLowerCase())
        || (route.title.toString().toLowerCase() === 'home') && router.context.url.pathname === '/') {
      if(router.context.url.pathname === `/${route.title.toString().toLowerCase()}`) {
          return html`
          <a class="nav__button active">
            <div class="nav__button-icon">${route.icon}</div>
            <div class="nav__button-title">${route.title}</div>
          </a>
      `;
      } else {
        return html`
        <a href="${route.path}" class="nav__button active">
          <div class="nav__button-icon">${route.icon}</div>
          <div class="nav__button-title">${route.title}</div>
        </a>
    `;
      }
    }

    return html`
        <a href="${route.path}" class="nav__button">
          <div class="nav__button-icon">${route.icon}</div>
          <div class="nav__button-title">${route.title}</div>
        </a>
    `;
  }

  render() {
    return html`
        <footer>
          <nav class="nav">
            ${router.routes.map(
                route => this.renderNavButton(route))}
          </nav>
        </footer>
    `;
  }
}
