import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import WooCommerceRestApi from '@woocommerce/woocommerce-rest-api';
import { openDB } from 'idb';

import './pages/app-home';
import './components/header';
import './components/navigation';
import './components/loader';
import './components/progress';
import './styles/global.css';
import { router } from './router';

import './scripts/fetch.js';
//import './scripts/idb.js';
//import './scripts/utility.js';
//import {html} from "lit/development";

const WooCommerce = new WooCommerceRestApi({
  url: 'https://julabo.us/', // Your store URL
  consumerKey: 'ck_9811532a1631e763bbc6148f9e71180eeb62a7b7', // Your consumer key
  consumerSecret: 'cs_cb62b9b8ac49b88a05d909d91e9bd32db5335dc6', // Your consumer secret
  version: 'wc/v3', // WooCommerce WP REST API version
  axiosConfig:{
    headers:{
      "Content-Type": "application/json;charset=UTF-8"
    }
  }
});

@customElement('app-index')
export class AppIndex extends LitElement {
  static styles = css`
    main {
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 16px;
    }
    .splash {
      background: #CC2229;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .splash__logo {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 48px 20px;
      padding-bottom: 0px;
    }
    .splash__title {
      text-align: center;
      color: #FFF;
      font-size: 24px;
      font-weight: 600;
      margin: 0;
      margin-top: 16px;
      padding: 0px 16px;
    }
    .progress__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .progress {
      appearance: none;
      height: 12px;
      margin-top: 30px;
      margin-bottom: 8px;
      border: 1px solid #fff;
    }
    /* Chrome and Safari */
    .progress::-webkit-progress-bar {
      background: #CC2229;
    }

    .progress::-webkit-progress-value {
      background-color: #fff;
    }
    .progress::-webkit-progress-value {
      transition: width 1s;
    }

    /* Firefox */
    .progress::-moz-progress-bar {
      background-color: #fff;
    }

    .progress__text {
      font-size: 12px;
    }
    .loader {
      margin-top: 16px;
      width: 50px;
      padding: 8px;
      aspect-ratio: 1;
      border-radius: 50%;
      background: #FFF;
      --_m:
          conic-gradient(#0000 10%,#000),
          linear-gradient(#000 0 0) content-box;
      -webkit-mask: var(--_m);
      mask: var(--_m);
      -webkit-mask-composite: source-out;
      mask-composite: subtract;
      animation: l3 1s infinite linear;
    }
    @keyframes l3 {to{transform: rotate(1turn)}}
  `;

  static properties = {
    loading: { type: Boolean },
    progress: { type: Number },
  };

  firstUpdated() {
    router.addEventListener('route-changed', () => {
      if ("startViewTransition" in document) {
        (document as any).startViewTransition(() => this.requestUpdate());
      }
      else {
        this.requestUpdate();
      }
    });
  }

  connectedCallback() {
    super.connectedCallback();

    if (!posts) {
      this.fetchData();
    }
  }

  async readAllData(channel) {
    const db = await openDB('posts-store', 1, {
      upgrade(db) {
        // Create a store of objects
        const store = db.createObjectStore('julabo', {
          // The 'id' property of the object will be the key.
          keyPath: 'id',
          // If it isn't explicitly set, create a value by auto incrementing.
          autoIncrement: true,
        });

        // Create an index to identify items of a particular channel
        store.createIndex("channel", "channel");
      },
    });

    let data = null;
    data = await db.getAllFromIndex('julabo', 'channel', channel).catch((error) => {
      // error handling
      console.log(error);
      return null;
    });

    return data;
  }

  fetchProductsByPage(page) {
    return WooCommerce.get(`products?per_page=100&page=${page}&status=publish`)
        .then((response) => {
          let products = [];
          response.data.forEach(product => {
            let tempProd = {
              "wooid": product.id,
              "sku": product.sku,
              "title": product.name,
              "slug": product.name
                  .toLowerCase()
                  .trim()
                  .replace(/[^\w\s-]/g, '')
                  .replace(/[\s_-]+/g, '-')
                  .replace(/^-+|-+$/g, ''),
              "short_description": product.short_description, //remove tags
              "price": product.price,
              "regular_price": product.regular_price,
              "price_html": product.price_html,
              "sale_price": product.sale_price,
              "promotion": "",
              "promotion_details": "",
              "product_categories": product.categories,
              "featured": product.featured,//
              "weight": product.weight,
              "length": product.dimensions.length,
              "width": product.dimensions.width,
              "height": product.dimensions.height,
              "key_benefits": "",//
              "available_power_options": "",
              "product_data_sheet": "", //pull from resource data
              "manual": "", //pull from manual data
              "product_heating_or_cooling_capability": "",
              "cooling_capacity": "",
              "product_cooling_capacity": "", //
              "product_heating_capacity_kw": "1 @ 115 V|2 @ 230 V",
              "product_fluid_type": "",
              "product_pump_capacity_flow_pressure_psi": "",
              "product_pump_capacity_flow_fate_l_min": "",
              "product_working_temp_range_c": "",
              "product_working_temp_range_low_c": "",
              "product_recommended_accessories": [],
              "product_weight_lbs": product.weight, //
              "formulas_min_temp": "",
              "formulas_max_temp": "",
              "max_capacity": "",
              "formulas_min_total_volume": "",
              "formulas_max_total_volume": "",
              "formulas_min_reactor_volume": "",
              "formulas_max_reactor_volume": "",
              "product_image": "",
              "product_link": product.permalink,
              "app_visible": false
            }

            if(product.meta_data) {
              product.meta_data.forEach(dataset => {
                if (dataset.key === 'key_benefits') {
                  tempProd.key_benefits = dataset.value;
                } else if (dataset.key === 'heating_cooling_capability') {
                  tempProd.product_heating_or_cooling_capability = dataset.value;
                } else if (dataset.key === 'cooling_capacity') {
                  tempProd.cooling_capacity = dataset.value;
                  tempProd.product_cooling_capacity = dataset.value;
                } else if (dataset.key === 'heating_capacity') {
                  tempProd.heating_capacity = dataset.value;
                } else if (dataset.key === 'fluid') {
                  let types = "";
                  if(dataset.value.length > 0){
                    dataset.value.forEach((subset, idx) => {
                      if (subset.length > 0) {
                        if (idx > 0) {
                          types = types + '|';
                        }
                        types = `${types}${subset}`;
                      }
                    });
                  }
                  tempProd.product_fluid_type = types;
                } else if (dataset.key === 'working_temp_range_low') {
                  tempProd.product_working_temp_range_low_c = dataset.value;
                  tempProd.formulas_min_temp = dataset.value;
                } else if (dataset.key === 'working_temp_range_high') {
                  tempProd.formulas_max_temp = dataset.value;
                } else if (dataset.key === 'working_temp__high') {
                  tempProd.formulas_max_temp = dataset.value;
                } else if (dataset.key === 'min_reactor_volume') {
                  tempProd.formulas_min_reactor_volume = dataset.value;
                } else if (dataset.key === 'max_reactor_volume') {
                  tempProd.formulas_max_reactor_volume = dataset.value;
                } else if (dataset.key === 'min_total_volume') {
                  tempProd.formulas_min_total_volume = dataset.value;
                } else if (dataset.key === 'max_total_volume') {
                  tempProd.formulas_max_total_volume = dataset.value;
                } else if (dataset.key === 'promotion') {
                  tempProd.promotion = dataset.value;
                } else if (dataset.key === 'promotion_details') {
                  tempProd.promotion_details = dataset.value;
                }
              })
            }
            if(product.images.length > 0) {
              tempProd.product_image = product.images[0].src;
            }

            let heatingCapacityString = '';
            let maxCapacity = null;
            product.product_variations.forEach((variation, idx) => {
              if(idx === 0) {
                variation.meta_data.forEach(meta => {
                  if(meta.key === "pump_capacity_flow_pressure") {
                    tempProd.product_pump_capacity_flow_pressure_psi = meta.value;
                  } else if (meta.key === "pump_capacity_flow_rate") {
                    tempProd.product_pump_capacity_flow_fate_l_min = meta.value;
                  } else if (meta.key === "heating_capacity") {
                    if(meta.value && (!maxCapacity || parseFloat(meta.value) > maxCapacity)) {
                      maxCapacity = parseFloat(meta.value);
                    }
                  }
                })
              }

              variation.meta_data.forEach(meta => {
                if (meta.key === "heating_capacity") {
                  if(meta.value && (!maxCapacity || parseFloat(meta.value) > maxCapacity)) {
                    maxCapacity = parseFloat(meta.value);
                  }
                }
              })

              variation.attributes.forEach(attr => {
                if(attr.slug === "pa_power-options") {
                  if(heatingCapacityString === '') {
                    heatingCapacityString = `${attr.option}`;
                  } else {
                    heatingCapacityString = `${heatingCapacityString} | ${attr.option}`;
                  }
                }
              })
            })

            if(maxCapacity) {
              tempProd.max_capacity = maxCapacity;
            }
            tempProd.product_heating_capacity_kw = heatingCapacityString;

            product.attributes.forEach(attr => {
              if(attr.slug === 'pa_recommended-accessories') {
                tempProd.product_recommended_accessories = attr.options;
              } else if (attr.slug === 'pa_power-options') {
                let optString = '';
                attr.options.forEach((opt,idx) => {
                  if(idx > 0) {
                    optString = optString + ' | '
                  }
                  optString = `${optString}${opt}`
                })

                tempProd.available_power_options = optString;
              }
            })

            if(tempProd.formulas_min_temp.length > 0 && tempProd.formulas_max_temp.length > 0) {
              tempProd.product_working_temp_range_c = `${tempProd.formulas_min_temp}...${tempProd.formulas_max_temp}`;
            }

            products.push(tempProd);
          })

          if(productData) {
            productData = [...productData, ...products];
          } else {
            productData = products;
          }

        })
        .catch((error) => {
          console.log(error);
        });
  }

  fetchCategoriesByPage(update) {
    return WooCommerce.get(`products/categories?per_page=100&exclude=23,1094,1761,1410`)
        .then((response) => {
          let catArray = [];
          response.data.forEach(cat => {
            catArray.push({
              'wooid': cat.id,
              'title': cat.name,
              'parent': cat.parent,
              'slug': cat.slug,
              'image': cat.image?.src ?? null,
            })
          });

          if(!update) {
            if(categories) {
              categories = [...categories, ...catArray];
            } else {
              categories = catArray;
            }
          }

        })
        .catch((error) => {
          console.log(error);
        });
  }

  async fetchPostsByPage(update) {
    const response = await fetch(`https://julabo.us/wp-json/wp/v2/posts?per_page=3&_embed`);
    const jsonResponse = await response.json();
    let resArray = [];
    jsonResponse.forEach(res => {
      resArray.push({
        'title': res.title.rendered,
        'wpid': res.id,
        'content': res.content.rendered,
        'image': res._embedded['wp:featuredmedia']['0'].source_url
      })
    })

    if(!update) {
      if(posts) {
        posts = [...posts, ...resArray];
      } else {
        posts = resArray;
      }
    }
  }

  async fetchProducts() {
    this.readAllData('products')
        .then((data) => {
          if(data && data.length > 0) {
            console.log('product data found.. returning')
            productData = data;
            console.log(productData);

            const cacheDate = new Date(productData[0].cachetime)
            const curDate = new Date();
            const expiry = 10*60*1000;

            if(curDate - cacheDate > expiry) {
              console.log('product data is outdated, updating..');
              const promises = [];
              for (let i = 1; i < 8; ++i) {
                promises.push(this.fetchProductsByPage(i));
              }

              Promise.all(promises)
                  .then((results) => {
                    console.log('updated product data');
                  })
                  .catch((e) => {
                    // Handle errors here
                  });
            }
          } else {
            console.log('product data not found, fetching..')
            const promises = [];
            for (let i = 1; i < 8; ++i) {
              promises.push(this.fetchProductsByPage(i));
            }

            Promise.all(promises)
                .then((results) => {

                })
                .catch((e) => {
                  // Handle errors here
                });
          }
        });
  }

  async fetchCategories() {
    if(!categories) {
      this.readAllData('categories')
          .then((data) => {
            if (data && data.length > 0) {
              console.log('category data found.. returning')
              categories = data;
              console.log(categories);
              this.progress = 100;
              this.fetchProducts();
              this.loading = false;

              const cacheDate = new Date(categories[0].cachetime)
              const curDate = new Date();
              const expiry = 12*60*60*1000;

              if(curDate - cacheDate > expiry) {

                const promises = [];
                for (let i = 1; i < 2; ++i) {
                  promises.push(this.fetchCategoriesByPage(true));
                }

                Promise.all(promises)
                    .then((results) => {
                      console.log('updated category data');
                    })
                    .catch((e) => {
                      // Handle errors here
                    });
              }
            } else {
              console.log('category data not found, fetching..')
              const promises = [];
              for (let i = 1; i < 2; ++i) {
                promises.push(this.fetchCategoriesByPage(false));
              }

              Promise.all(promises)
                  .then((results) => {
                    this.progress = 100;
                    this.fetchProducts();
                    this.loading = false;
                  })
                  .catch((e) => {
                    // Handle errors here
                  });
            }
          });
    }
  }

  async fetchData() {
    this.loading = true;
    const response = await fetch(`https://spstotest2.fastgenapp.com/data`);
    const jsonResponse = await response.json();
    console.log(jsonResponse);
    categories = jsonResponse.categories.Result;
    productData = jsonResponse.products.Result;
    posts = jsonResponse.posts.Result;
    territories = jsonResponse.territories.Result;
    resources = jsonResponse.resources.Result;

    //convert strings back to arrays
    productData.forEach(product => {
      if(product.product_categories) {
        product.product_categories = JSON.parse(product.product_categories);
      }
    })
    territories.forEach(territory => {
      if(territory.areas) {
        territory.areas = JSON.parse(territory.areas);
      }
    })
    this.loading = false;
  }

  async fetchPosts() {
    this.loading = true;
    this.progress = 13;

    if(!posts) {
      this.readAllData('posts')
          .then((data) => {
            if (data && data.length > 0) {
              console.log('post data found.. returning')
              posts = data;
              console.log(posts);
              this.progress = 20;
              this.fetchData();

              const cacheDate = new Date(posts[0].cachetime)
              const curDate = new Date();
              const expiry = 60*60*1000;

              if(curDate - cacheDate > expiry) {
                console.log('posts data is outdated, updating..');
                const promises = [];
                for (let i = 1; i < 2; ++i) {
                  promises.push(this.fetchPostsByPage(true));
                }

                Promise.all(promises)
                    .then((results) => {
                      console.log('posts data updated');
                    })
                    .catch((e) => {
                      // Handle errors here
                    });
              }
            } else {
              console.log('post data not found, fetching..')
              const promises = [];
              for (let i = 1; i < 2; ++i) {
                promises.push(this.fetchPostsByPage(false));
              }

              Promise.all(promises)
                  .then((results) => {
                    this.progress = 20;
                    this.fetchData();
                  })
                  .catch((e) => {
                    // Handle errors here
                  });
            }
          });
    }
  }

  render() {
    if (this.loading) {
      return html`
        <div class="splash">
          <div class="splash__logo">
            <svg width="198" height="43" viewBox="0 0 198 43" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M56.9305 27.8838C56.9305 20.9862 56.9305 13.5016 56.9305 13.0613C56.9305 11.8872 57.9657 10.5664 59.4444 10.5664C60.4795 10.5664 81.6251 10.5664 81.6251 10.5664V30.5254H74.3794V15.8497H64.0284V25.6824H73.9358L69.056 30.5254C69.056 30.5254 62.4018 30.5254 59.2965 30.5254C58.2614 30.5254 56.9305 29.4981 56.9305 27.8838Z" fill="white"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M21.5892 28.0306C21.5892 27.5903 21.5892 10.5664 21.5892 10.5664C21.5892 10.5664 26.469 10.5664 28.835 10.5664C28.835 10.7132 28.835 25.6824 28.835 25.6824H39.186C39.186 25.6824 39.186 12.3275 39.186 10.7132V10.5664C41.5519 10.5664 43.9179 10.5664 46.4317 10.5664C46.4317 10.5664 46.4317 26.7098 46.4317 28.0306C46.4317 29.2046 45.3966 30.3787 43.9179 30.3787C43.4742 30.3787 24.3988 30.3787 24.2509 30.3787C22.1807 30.5255 21.4414 28.7644 21.5892 28.0306Z" fill="white"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1.92233 28.0309C1.92233 25.9763 1.92233 23.9217 1.92233 21.8671C4.14041 21.8671 6.94997 21.8671 9.16804 21.8671V25.6828C10.2031 25.6828 11.534 25.6828 12.5691 25.6828C12.5691 25.6828 12.5691 1.61453 12.5691 1.46777C14.935 1.46777 17.4488 1.46777 19.8148 1.46777C19.8148 10.42 19.8148 19.0787 19.8148 28.0309C19.8148 29.205 18.9276 30.5258 17.301 30.5258C13.3084 30.5258 4.73189 30.5258 4.73189 30.5258C2.51382 30.5258 1.92233 28.6179 1.92233 28.0309Z" fill="white"/>
              <path d="M55.304 1.46777H48.0583V30.5258H55.304V1.46777Z" fill="white"/>
              <path d="M131.754 10.5664H112.087C111.052 10.5664 109.573 11.5937 109.573 13.0613C109.573 14.0886 109.573 24.2149 109.573 27.8838C109.573 29.3514 110.608 30.5254 112.087 30.5254C112.382 30.5254 131.458 30.5254 131.754 30.5254C133.232 30.5254 134.415 29.3514 134.415 27.8838C134.415 24.2149 134.415 14.0886 134.415 13.0613C134.267 11.5937 132.789 10.5664 131.754 10.5664ZM127.022 25.6824H116.819V15.8497H127.022V25.6824Z" fill="white"/>
              <path d="M105.432 10.5667C105.432 10.5667 91.9761 10.5667 90.4974 10.5667C90.4974 10.42 90.4974 1.61453 90.4974 1.46777C87.9836 1.46777 85.7655 1.46777 83.2517 1.46777C83.2517 11.1538 83.2517 20.8398 83.2517 30.5258C87.2442 30.5258 105.137 30.5258 105.432 30.5258C106.911 30.5258 108.094 29.3517 108.094 27.8842C108.094 24.2152 108.094 14.0889 108.094 13.0616C107.946 11.5941 106.468 10.5667 105.432 10.5667ZM90.3495 25.6828V15.85H100.553V25.6828H90.3495Z" fill="white"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M145.062 28.0303C145.062 27.59 145.062 10.7129 145.062 10.7129C145.062 10.7129 149.794 10.7129 152.16 10.7129C152.16 10.8596 152.16 25.6822 152.16 25.6822H162.511C162.511 25.6822 162.511 12.3272 162.511 10.8596V10.7129C164.877 10.7129 167.243 10.7129 169.609 10.7129C169.609 10.7129 169.609 26.7095 169.609 28.1771C169.609 29.3511 168.574 30.5252 167.095 30.5252C166.651 30.5252 147.724 30.5252 147.576 30.5252C145.653 30.5252 145.062 28.9108 145.062 28.0303Z" fill="white"/>
              <path d="M195.634 20.5457C195.634 19.0781 194.155 18.0508 192.972 18.0508H175.967V15.8494H195.486C195.486 14.5286 195.486 13.5013 195.486 13.2078C195.486 11.7402 194.007 10.7129 192.824 10.7129H175.967H173.453C172.418 10.7129 171.087 11.5934 170.94 12.9143C170.94 13.061 170.94 13.061 170.94 13.2078C170.94 13.5013 170.94 14.5286 170.94 15.8494V20.5457V20.6924C170.94 22.16 172.418 23.1873 173.601 23.1873H190.459V25.5354H170.94C170.94 26.8562 170.94 27.8835 170.94 28.1771C170.94 29.6446 172.418 30.6719 173.601 30.6719H193.12C194.155 30.6719 195.634 29.6446 195.634 28.1771C195.634 27.8835 195.634 20.9859 195.634 20.8392C195.634 20.6924 195.634 20.5457 195.634 20.5457Z" fill="white"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M136.781 29.0582C136.781 29.9387 137.521 30.5257 138.26 30.5257H141.069C141.957 30.5257 142.548 29.7919 142.548 29.0582V26.5633C142.548 25.6827 141.809 25.0957 141.069 25.0957H138.26C137.373 25.0957 136.781 25.8295 136.781 26.5633V29.0582Z" fill="white"/>
              <path d="M3.54892 41.5325V37.2765H2.07021V36.6895H5.76699V37.2765H4.28828V41.5325H3.54892Z" fill="white"/>
              <path d="M15.6744 41.5325H12.717V36.6895H15.6744V37.2765H13.3084V38.8908H15.5265V39.4778H13.3084V41.0922H15.6744V41.5325Z" fill="white"/>
              <path d="M27.2084 41.5325V37.8635L25.5818 41.5325H25.1382L23.5116 37.8635V41.5325H22.9201V36.6895H23.6595L25.286 40.7987L26.9126 36.6895H27.652V41.5325H27.2084Z" fill="white"/>
              <path d="M36.82 36.6895C37.7072 36.6895 38.4466 37.2765 38.4466 38.3038C38.4466 39.1843 37.8551 39.9181 36.82 39.9181H35.637V41.6792H35.0456V36.6895H36.82ZM35.637 39.1843H36.6721C37.4115 39.1843 37.7072 38.7441 37.7072 38.157C37.7072 37.57 37.2636 37.1297 36.6721 37.1297H35.637V39.1843Z" fill="white"/>
              <path d="M48.5019 41.5325H45.5444V36.6895H48.5019V37.2765H46.1359V38.8908H48.354V39.4778H46.1359V41.0922H48.5019V41.5325Z" fill="white"/>
              <path d="M56.3391 41.5325H55.7476V36.6895H57.522C58.4093 36.6895 59.1486 37.2765 59.1486 38.157C59.1486 38.8908 58.8529 39.3311 58.1135 39.4778L59.2965 41.5325H58.5571L57.522 39.6246H56.3391V41.5325ZM56.3391 39.0376H57.522C58.1135 39.0376 58.5571 38.7441 58.5571 38.157C58.5571 37.57 58.1135 37.2765 57.522 37.2765H56.3391V39.0376Z" fill="white"/>
              <path d="M68.9081 40.0649H66.9858L66.5422 41.5325H65.9507L67.7252 36.6895H68.3167L70.0911 41.5325H69.4996L68.9081 40.0649ZM67.2816 39.6246H68.9081L68.0209 37.4232L67.2816 39.6246Z" fill="white"/>
              <path d="M77.6326 41.5325V37.2765H76.1539V36.6895H79.8506V37.2765H78.3719V41.5325H77.6326Z" fill="white"/>
              <path d="M87.3921 39.7714C87.3921 40.5052 87.8357 41.0922 88.7229 41.0922C89.6102 41.0922 90.0538 40.5052 90.0538 39.7714V36.6895H90.6453V39.7714C90.6453 40.7987 89.9059 41.5325 88.7229 41.5325C87.54 41.5325 86.8006 40.7987 86.8006 39.7714V36.6895H87.3921V39.7714Z" fill="white"/>
              <path d="M98.6303 41.5325H98.0388V36.6895H99.8133C100.701 36.6895 101.44 37.2765 101.44 38.157C101.44 38.8908 101.144 39.3311 100.405 39.4778L101.588 41.5325H100.848L99.8133 39.6246H98.6303V41.5325ZM98.6303 39.0376H99.8133C100.405 39.0376 100.848 38.7441 100.848 38.157C100.848 37.57 100.405 37.2765 99.8133 37.2765H98.6303V39.0376Z" fill="white"/>
              <path d="M111.643 41.5325H108.686V36.6895H111.643V37.2765H109.277V38.8908H111.495V39.4778H109.277V41.0922H111.643V41.5325Z" fill="white"/>
              <path d="M126.43 39.0379C126.43 37.5703 127.317 36.543 128.648 36.543C129.683 36.543 130.571 37.13 130.718 38.1573H130.127C129.979 37.5703 129.388 37.13 128.648 37.13C127.761 37.13 127.022 37.8638 127.022 39.0379C127.022 40.2119 127.613 40.9457 128.648 40.9457C129.388 40.9457 129.831 40.6522 130.127 40.0652H130.718C130.423 41.0925 129.831 41.5327 128.648 41.5327C127.317 41.5327 126.43 40.6522 126.43 39.0379Z" fill="white"/>
              <path d="M142.105 39.0379C142.105 40.5054 141.217 41.5327 139.886 41.5327C138.556 41.5327 137.668 40.5054 137.668 39.0379C137.668 37.5703 138.556 36.543 139.886 36.543C141.217 36.543 142.105 37.5703 142.105 39.0379ZM138.26 39.0379C138.26 40.2119 138.851 40.9457 139.886 40.9457C140.922 40.9457 141.513 40.2119 141.513 39.0379C141.513 37.8638 140.922 37.13 139.886 37.13C138.851 37.13 138.26 37.8638 138.26 39.0379Z" fill="white"/>
              <path d="M149.35 41.5325V36.6895H149.942L152.603 40.5052V36.6895H153.195V41.5325H152.603L149.942 37.7168V41.5325H149.35Z" fill="white"/>
              <path d="M161.771 41.5325V37.2765H160.293V36.6895H163.99V37.2765H162.511V41.5325H161.771Z" fill="white"/>
              <path d="M171.531 41.5325H170.94V36.6895H172.714C173.601 36.6895 174.341 37.2765 174.341 38.157C174.341 38.8908 174.045 39.3311 173.305 39.4778L174.488 41.5325H173.749L172.714 39.6246H171.531V41.5325ZM171.531 39.0376H172.714C173.305 39.0376 173.749 38.7441 173.749 38.157C173.749 37.57 173.305 37.2765 172.714 37.2765H171.531V39.0376Z" fill="white"/>
              <path d="M185.875 39.0379C185.875 40.5054 184.987 41.5327 183.656 41.5327C182.326 41.5327 181.438 40.5054 181.438 39.0379C181.438 37.5703 182.326 36.543 183.656 36.543C184.987 36.543 185.875 37.5703 185.875 39.0379ZM182.03 39.0379C182.03 40.2119 182.621 40.9457 183.656 40.9457C184.692 40.9457 185.283 40.2119 185.283 39.0379C185.283 37.8638 184.692 37.13 183.656 37.13C182.621 37.13 182.03 37.8638 182.03 39.0379Z" fill="white"/>
              <path d="M195.93 41.5325H192.972V36.6895H193.564V40.9454H195.93V41.5325Z" fill="white"/>
            </svg>
          </div>
          <h1 class="splash__title">We heat things up and <br/>cool them down℠</h1>
          <div class="loader"></div>
        </div>`;
    }

    // router config can be round in src/router.ts
    return router.render();
  }
}
