const states = [
    { label: 'United States', value: 'Placeholder'},
    { label: 'Alabama', value: 'Alabama' },
    { label: 'Alaska', value: 'Alaska' },
    { label: 'Arizona', value: 'Arizona' },
    { label: 'Arkansas', value: 'Arkansas' },
    { label: 'California', value: 'California' },
    { label: 'Colorado', value: 'Colorado' },
    { label: 'Connecticut', value: 'Connecticut' },
    { label: 'Delaware', value: 'Delaware' },
    { label: 'District Of Columbia', value: 'District_Of_Columbia' },
    { label: 'Florida', value: 'Florida' },
    { label: 'Georgia', value: 'Georgia' },
    { label: 'Hawaii', value: 'Hawaii' },
    { label: 'Idaho', value: 'Idaho' },
    { label: 'Illinois', value: 'Illinois' },
    { label: 'Indiana', value: 'Indiana' },
    { label: 'Iowa', value: 'Iowa' },
    { label: 'Kansas', value: 'Kansas' },
    { label: 'Kentucky', value: 'Kentucky' },
    { label: 'Louisiana', value: 'Louisiana' },
    { label: 'Maine', value: 'Maine' },
    { label: 'Maryland', value: 'Maryland' },
    { label: 'Massachusetts', value: 'Massachusetts' },
    { label: 'Michigan', value: 'Michigan' },
    { label: 'Minnesota', value: 'Minnesota' },
    { label: 'Mississippi', value: 'Mississippi' },
    { label: 'Missouri', value: 'Missouri' },
    { label: 'Montana', value: 'Montana' },
    { label: 'Nebraska', value: 'Nebraska' },
    { label: 'Nevada', value: 'Nevada' },
    { label: 'New Hampshire', value: 'New_Hampshire' },
    { label: 'New Jersey', value: 'New_Jersey' },
    { label: 'New Mexico', value: 'New_Mexico' },
    { label: 'New York', value: 'New_York' },
    { label: 'North Carolina', value: 'North_Carolina' },
    { label: 'North Dakota', value: 'North_Dakota' },
    { label: 'Ohio', value: 'Ohio' },
    { label: 'Oklahoma', value: 'Oklahoma' },
    { label: 'Oregon', value: 'Oregon' },
    { label: 'Pennsylvania', value: 'Pennsylvania' },
    { label: 'Puerto Rico', value: 'Puerto_Rico' },
    { label: 'Rhode Island', value: 'Rhode_Island' },
    { label: 'South Carolina', value: 'South_Carolina' },
    { label: 'South Dakota', value: 'South_Dakota' },
    { label: 'Tennessee', value: 'Tennessee' },
    { label: 'Texas', value: 'Texas' },
    { label: 'Utah', value: 'Utah' },
    { label: 'Vermont', value: 'Vermont' },
    { label: 'Virginia', value: 'Virginia' },
    { label: 'Washington', value: 'Washington' },
    { label: 'West Virginia', value: 'West_Virginia' },
    { label: 'Wisconsin', value: 'Wisconsin' },
    { label: 'Wyoming', value: 'Wyoming' },
    { label: 'Canada', value: 'Placeholder'},
    { label: 'Alberta', value: 'Alberta' },
    { label: 'British Columbia', value: 'British_Columbia' },
    { label: 'Manitoba', value: 'Manitoba' },
    { label: 'New Brunswick', value: 'New_Brunswick' },
    { label: 'Newfoundland and Labrador', value: 'Newfoundland_and_Labrador' },
    { label: 'Northwest Territories', value: 'Northwest_Territories' },
    { label: 'Nova Scotia', value: 'Nova_Scotia' },
    { label: 'Nunavut', value: 'Nunavut' },
    { label: 'Ontario', value: 'Ontario' },
    { label: 'Prince Edward Island', value: 'Prince_Edward_Island' },
    { label: 'Quebec', value: 'Quebec' },
    { label: 'Saskatchewan', value: 'Saskatchewan' },
    { label: 'Yukon', value: 'Yukon' }
];
export default states;
