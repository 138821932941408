import { LitElement, css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';

@customElement('app-loader')
export class AppLoader extends LitElement {

  static styles = css`
    .loader__bg {
      position: absolute;
      width: 100%;
      height: 100%;
      background: #FFF;
      left: 0px;
      top: 0px;
      display: flex;
      align-items:center;
      justify-content: center;
    }
    .loader {
      width: 50px;
      padding: 8px;
      aspect-ratio: 1;
      border-radius: 50%;
      background: #E9262A;
      --_m:
          conic-gradient(#0000 10%,#000),
          linear-gradient(#000 0 0) content-box;
      -webkit-mask: var(--_m);
      mask: var(--_m);
      -webkit-mask-composite: source-out;
      mask-composite: subtract;
      animation: l3 1s infinite linear;
    }
    @keyframes l3 {to{transform: rotate(1turn)}}
  `;

  render() {
    return html`
      <div class="loader__bg">
        <div>
          <div class="loader"></div>  
        </div>
      </div>
    `;
  }
}
