import { LitElement, css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';
import { resolveRouterPath } from '../router';

import '@shoelace-style/shoelace/dist/components/button/button.js';
@customElement('app-header')
export class AppHeader extends LitElement {
  @property({ type: String }) title = 'julabo-app';

  @property({ type: Boolean}) enableBack: boolean = false;

  static styles = css`
    header {
      background: #FFFFFF;
      border-bottom: 1px solid #DADADA;
    }
    
    header .back-nav {
      padding: 16px 20px;
    }
    nav a {
      margin-left: 10px;
    }

    @media(prefers-color-scheme: light) {
      header {
        color: black;
      }

      nav a {
        color: initial;
      }
    }
  `;

  handleBack() {
    history.back();
  }

  render() {
    return html`
      ${this.enableBack ? html`
      <header>
        <div class="back-nav">
            <div @click="${this.handleBack}">
              <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 1L2 8L9 15" stroke="#454545" stroke-width="2" stroke-linecap="round"/>
              </svg>
            </div>
        </div>
      </header>` : null}
    `;
  }
}
